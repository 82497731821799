window.addEventListener('DOMContentLoaded', function () {
	//modal window
	function closeModalWindow() {
		let ElmnBody = document.querySelector('body');
		ElmnBody.classList.remove('c-body-modal-widow-fixed');

		let modalWindow = document.querySelector('.js-modal-window');
		modalWindow.style.display = 'none';
	}

	let modalWindow = document.querySelector('.js-modal-window');
	if (!!modalWindow) {
		modalWindow.addEventListener('click', function (event) {
			closeModalWindow();
		});
	}

	let modalContent = document.querySelector('.js-modal-window-content');
	if (!!modalContent) {
		modalContent.addEventListener('click', function (event) {
			event.stopPropagation();
		});
	}

	let closeModalBtn = document.querySelectorAll('.js-modal-window-close');
	closeModalBtn.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			closeModalWindow();
		});
	});

	let openModalBtn = document.querySelectorAll('.js-modal-window-open');
	openModalBtn.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			// HTML上のdata-is-appliedから値を取得し、モーダル内で表示する内容を変更しています。
			// 動作確認のために実装したため、不要であれば後ろに * がついた行を削除してください。
			let isApplied = this.getAttribute('data-is-applied'); // *
			let modalWindow = document.querySelector('.js-modal-window');
			modalWindow.setAttribute('data-is-applied', isApplied); // *
			modalWindow.style.display = 'flex';

			let ElmnBody = document.querySelector('body');
			ElmnBody.classList.add('c-body-modal-widow-fixed');
		});
	});
})