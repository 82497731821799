window.addEventListener('DOMContentLoaded', function () {
	function removeActiveNav() {
		let activeNav = document.querySelector('.js-2column__section__applicant-lg__nav__list__item[data-is-active="true"]');
		if (activeNav) {
			activeNav.setAttribute('data-is-active', 'false');
		}
	}

	let applicantNavItem = document.querySelectorAll('.js-2column__section__applicant-lg__nav__list__item');
	applicantNavItem.forEach(function (elmn, index) {
		elmn.addEventListener('click', function () {
			let isActive = this.getAttribute('data-is-active');
			if (isActive == 'true') {
				return;
			}
			removeActiveNav();

			let userName = this.getAttribute('data-applicant-name');
			let applicantContentUserName = document.querySelector('.js-2column__section__applicant-lg__content__username');
			this.setAttribute('data-is-active', 'true');
			applicantContentUserName.innerHTML = userName;
		});
	});



	function removeActiveNavSm() {
		let activeNav = document.querySelector('.js-2column__section__applicant-sm__content__nav__list__item[data-is-active="true"]');
		if (activeNav) {
			activeNav.setAttribute('data-is-active', 'false');
		}
	}

	function closeApplicantNav() {
		let applicantNav = document.querySelector('.js-2column__section__applicant-sm__content__nav__list');
		let isOpen = applicantNav.getAttribute('data-is-open');
		if (isOpen == 'false') {
			applicantNav.setAttribute('data-is-open', 'true');
		} else {
			applicantNav.setAttribute('data-is-open', 'false');
		}
	}

	let applicanNavBtn = document.querySelector('.js-2column__section__applicant-sm__content__nav__btn');
	if (!!applicanNavBtn) {
		applicanNavBtn.addEventListener('click', function () {
			closeApplicantNav();
		});
	}

	let applicanNavItemSm = document.querySelectorAll('.js-2column__section__applicant-sm__content__nav__list__item');
	applicanNavItemSm.forEach(function (elmn, index) {
		elmn.addEventListener('click', function () {
			let isActive = this.getAttribute('data-is-active');
			if (isActive == 'true') {
				return;
			}
			removeActiveNavSm();
			closeApplicantNav();

			let userName = this.getAttribute('data-applicant-name');
			let applicantContentUserName = document.querySelector('.js-2column__section__applicant-sm__content__username');
			this.setAttribute('data-is-active', 'true');
			applicantContentUserName.innerHTML = userName;
		});
	});
})