window.addEventListener('DOMContentLoaded', function () {

	// PC用
	//検索選択リスト内が選択された時の挙動制御
	let searchRadio = document.querySelectorAll('.js-jumbtron__search__input input[type="radio"]');
	searchRadio.forEach(function (elmn, index) {
		elmn.addEventListener('change', function () {
			let selectedName = this.getAttribute('data-value-name');
			let inputName = this.getAttribute('name');
			let tergetElmn = document.querySelector('.js-jumbtron__search__select[data-search-title="' + inputName + '"');
			tergetElmn.querySelector('.js-jumbtron__search__select__name').textContent = selectedName;
			// closeCurrentOpenSearch();
		});
	});

	// 検索選択リスト内のアコーディオンのコンテンツにmax-heightを追加
	let searchAccordionContent = document.querySelectorAll('.js-jumbtron__search__input__item__accordion');
	searchAccordionContent.forEach(function (elmn, index) {
		let height = elmn.clientHeight;
		elmn.style.maxHeight = height + 'px';
	});

	//検索選択リスト内のアコーディオン開閉制御
	let searchAccordionHeader = document.querySelectorAll('.js-jumbtron__search__input__item__header');
	searchAccordionHeader.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
			let isInputOpen = this.getAttribute('data-is-open');
			if (isInputOpen === 'true') {
				this.setAttribute('data-is-open', 'false');
			} else {
				this.setAttribute('data-is-open', 'true');
			}
		});
		elmn.setAttribute('data-is-open', 'false');
	});

	let searchAccordionInput = document.querySelectorAll('.js-jumbtron__search__input');
	searchAccordionInput.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
		});
		elmn.removeAttribute('style');
	});


	//ジャンボトロン内の検索窓の開閉
	function closeCurrentOpenSearch() {
		let elmnSearchSelectOpen = document.querySelector('.js-jumbtron__search__select[data-is-open="true"]');
		if (elmnSearchSelectOpen == null) {
			return;
		}
		let searchTitleOpen = elmnSearchSelectOpen.getAttribute('data-search-title');
		let elmnInputOpen = document.querySelector('.js-jumbtron__search__input[data-search-title="' + searchTitleOpen + '"]');
		let ElmnBody = document.querySelector('body');

		elmnSearchSelectOpen.setAttribute('data-is-open', 'false');
		elmnInputOpen.style.display = 'none';
		ElmnBody.removeEventListener('click', closeCurrentOpenSearch);
	}

	let jumbtronSearchSelect = document.querySelectorAll('.js-jumbtron__search__select');
	jumbtronSearchSelect.forEach(function (elmn, index) {
		let searchTitle = elmn.getAttribute('data-search-title');
		let elmnInput = document.querySelector('.js-jumbtron__search__input[data-search-title="' + searchTitle + '"]');
		let elmnBody = document.querySelector('body');

		elmn.addEventListener('click', function (event) {
			event.stopPropagation();

			let isInputOpen = this.getAttribute('data-is-open');
			if (isInputOpen === 'true') {
				elmnInput.style.display = 'none';
				this.setAttribute('data-is-open', 'false');
			} else {
				closeCurrentOpenSearch();
				elmnInput.style.display = 'block';
				this.setAttribute('data-is-open', 'true');
				elmnBody.addEventListener('click', closeCurrentOpenSearch);
			}
		});

		elmnInput.style.display = 'none';

	})



	// SP用
	function closeSearchInputModal() {
		let openModal = document.querySelector('.js-jumbotron-sm-search-modal');
		let openInput = openModal.querySelector('.js-jumbtron-sm__search-modal__content[data-is-open="true"]')
		let ElmnBody = document.querySelector('body');

		openModal.setAttribute('data-is-open', 'false');
		openInput.setAttribute('data-is-open', 'false');
		ElmnBody.classList.remove('c-body-modal-widow-fixed');
	}

	function closeSpAccordion() {
		let openAccordion = document.querySelector('.js-jumbotron-sm-search-modal__content__list__accordion[data-is-open="true"]');
		if (openAccordion) {
			openAccordion.setAttribute('data-is-open', 'false');
		}
	}

	let spJumbotronAccordionBtn = document.querySelectorAll('.js-jumbotron-sm-search-modal__content__list__accordion');
	spJumbotronAccordionBtn.forEach(function (elmn, index) {

		// 検索選択リスト内のアコーディオンのコンテンツにmax-heightを追加
		let searchAccordionContent = elmn.querySelectorAll('.js-jumbotron-sm-search-modal__content__input__list__item-multi__accordion');
		searchAccordionContent.forEach(function (elmn, index) {
			let height = elmn.clientHeight;
			elmn.style.maxHeight = height + 'px';
		});

		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
			let isOpen = this.getAttribute('data-is-open');
			if (isOpen == 'true') {
				this.setAttribute('data-is-open', 'false');
			} else {
				closeSpAccordion();
				this.setAttribute('data-is-open', 'true');
			}
		});

		elmn.setAttribute('data-is-open', 'false');
	});

	// アコーディオン内の項目のクリックイベント
	let spAccordionItem = document.querySelectorAll('.js-jumbotron-sm-search-modal__content__input__list__item-multi__accordion__list__item');
	spAccordionItem.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
			let inputValueName = this.getAttribute('data-value-name');
			let inputValue = this.getAttribute('data-value');
			let spSearchAreaElmn = document.querySelector('.js-jumbtron-sm__search__select[data-search-title="prefecture_id"]');
			let spSearchAreaNameElm = spSearchAreaElmn.querySelector('.js-jumbtron-sm__search__select__name');
			let inputElmn = spSearchAreaElmn.querySelector('input[name="prefecture_id"]');

			inputElmn.value = inputValue;
			spSearchAreaNameElm.innerHTML = inputValueName;
			closeSearchInputModal();
		});
	});

	let spJumbotronSearchInputCloseBtn = document.querySelectorAll('.js-jumbotron-sm-search-modal__list__close-btn');
	spJumbotronSearchInputCloseBtn.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
			closeSearchInputModal();
		});
	});


	// 検索の項目クリックでモーダルを開く
	let spJumbotronSearchOpenBtn = document.querySelectorAll('.js-jumbtron-sm__search__select');
	spJumbotronSearchOpenBtn.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
			let searchTitle = this.getAttribute('data-search-title');
			let openModal = document.querySelector('.js-jumbotron-sm-search-modal');
			let openInput = document.querySelector('.js-jumbtron-sm__search-modal__content[data-search-title="' + searchTitle + '"]')
			let ElmnBody = document.querySelector('body');

			openModal.setAttribute('data-is-open', 'true');
			openInput.setAttribute('data-is-open', 'true');
			ElmnBody.classList.add('c-body-modal-widow-fixed');
		});
	});

	let spJumbotronSearchInput = document.querySelectorAll('.js-jumbotron-sm-search-modal__content__list__item');
	spJumbotronSearchInput.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
			let inputValue = this.getAttribute('data-value');
			let inputValueName = this.getAttribute('data-value-name');
			let searchTitle = this.parentNode.parentNode.parentNode.getAttribute('data-search-title');
			let spSearchElmn = document.querySelector('.js-jumbtron-sm__search__select[data-search-title="' + searchTitle + '"]');

			let spSearchNameElm = spSearchElmn.querySelector('.js-jumbtron-sm__search__select__name');
			let inputElmn = spSearchElmn.querySelector('input[name="' + searchTitle + '"]');

			inputElmn.value = inputValue;
			spSearchNameElm.innerHTML = inputValueName;
			closeSearchInputModal();

		});
	});

	// 初期設定
	let spJumbotronSearchModalContent = document.querySelectorAll('.js-jumbtron-sm__search-modal__content');
	spJumbotronSearchModalContent.forEach(function (elmn, index) {
		elmn.setAttribute('data-is-open', 'false');
	});

	let spJumbotronSearchModal = document.querySelector('.js-jumbotron-sm-search-modal');
	if (!!spJumbotronSearchModal) {
		spJumbotronSearchModal.setAttribute('data-is-open', 'false');
		spJumbotronSearchModal.removeAttribute('style');
	}


	// swiper
	const swiper = new Swiper('.swiper', {
		speed: 600,
		slidesPerGroup: 5,
		slidesPerView: 5,
		spaceBetween: 15,
		loop: false,
		navigation: {
			nextEl: '.js-swiper-button-next',
			prevEl: '.js-swiper-button-prev',
		},
	});

	swiper.on('slideChange', function () {
		if (swiper.isBeginning) {
			document.querySelector('.js-swiper-button-prev').style.display = "none";
		} else if (swiper.isEnd) {
			document.querySelector('.js-swiper-button-next').style.display = "none";
		} else {
			document.querySelector('.js-swiper-button-prev').style.display = "block";
			document.querySelector('.js-swiper-button-next').style.display = "block";
		}
	});

})