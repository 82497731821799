window.addEventListener('DOMContentLoaded', function () {
	// PC
	let zipInput = document.querySelectorAll('.p-postal-code');
	let zipInputSm = document.querySelector('.js-form__register__item__input__address-zip__digit-sm');
	let zipCode = '';
	zipInput.forEach(function (elmn, index) {
		elmn.addEventListener('change', function () {
			zipCode = '';
			zipInput.forEach(function (elmn, index) {
				zipCode = zipCode + elmn.value;
				zipInputSm.value = zipCode
			});
		});
	});

	let addressAutoFillBtn = document.querySelector('.js-search-address-from-zip');
	if (!!addressAutoFillBtn) {
		addressAutoFillBtn.addEventListener('click', function (event) {
			let inputZip = document.querySelector('.js-form__register__item__input__address-zip__digit-sm');
			if (zipCode.value != '') {
				AjaxZip3.zip2addr(inputZip, '', 'organization[prefecture_id]', 'organization[city]', 'organization[city]');
			}
		});
	}

	let addressAutoFillBtnRegister = document.querySelector('.js-search-address-from-zip-register');
	if (!!addressAutoFillBtnRegister) {
		addressAutoFillBtnRegister.addEventListener('click', function (event) {
			let inputZip = document.querySelector('.js-form__register__item__input__address-zip__digit-sm');
			if (zipCode.value != '') {
				AjaxZip3.zip2addr(inputZip, '', 'account[organization][prefecture_id]', 'account[organization][city]', 'account[organization][city]');
			}
		});
	}
})