window.addEventListener('DOMContentLoaded', function () {
	// SP用
	function closeSearchInputModal() {
		let openModal = document.querySelector('.js-volunteer-list-sm-search-modal');
		let openInput = openModal.querySelector('.js-volunteer-list-sm__search-modal__content[data-is-open="true"]')
		let ElmnBody = document.querySelector('body');

		openModal.setAttribute('data-is-open', 'false');
		openInput.setAttribute('data-is-open', 'false');
		ElmnBody.classList.remove('c-body-modal-widow-fixed');
	}

	function closeSpAccordion() {
		let openAccordion = document.querySelector('.js-volunteer-list-sm-search-modal__content__list__accordion[data-is-open="true"]');
		if (openAccordion) {
			openAccordion.setAttribute('data-is-open', 'false');
		}
	}

	let spVolunteerListAccordionBtn = document.querySelectorAll('.js-volunteer-list-sm-search-modal__content__list__accordion');
	spVolunteerListAccordionBtn.forEach(function (elmn, index) {

		// 検索選択リスト内のアコーディオンのコンテンツにmax-heightを追加
		let searchAccordionContent = elmn.querySelectorAll('.js-volunteer-list-sm-search-modal__content__input__list__item-multi__accordion');
		searchAccordionContent.forEach(function (elmn, index) {
			let height = elmn.clientHeight;
			elmn.style.maxHeight = height + 'px';
		});

		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
			let isOpen = this.getAttribute('data-is-open');
			if (isOpen == 'true') {
				this.setAttribute('data-is-open', 'false');
			} else {
				closeSpAccordion();
				this.setAttribute('data-is-open', 'true');
			}
		});

		elmn.setAttribute('data-is-open', 'false');
	});

	// アコーディオン内の項目のクリックイベント
	let spAccordionItem = document.querySelectorAll('.js-volunteer-list-sm-search-modal__content__input__list__item-multi__accordion__list__item');
	spAccordionItem.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
			let inputValueName = this.getAttribute('data-value-name');
			let inputValue = this.getAttribute('data-value');
			let spSearchAreaElmn = document.querySelector('.js-2column__aside__search-sm__form__item[data-search-title="prefecture_id"]');
			let spSearchAreaNameElm = spSearchAreaElmn.querySelector('.js-2column__aside__search-sm__form__item__name');
			let inputElmn = spSearchAreaElmn.querySelector('input[name="prefecture_id"]');

			inputElmn.value = inputValue;
			spSearchAreaNameElm.innerHTML = inputValueName;
			closeSearchInputModal();
		});
	});

	let spVolunteerListSearchInputCloseBtn = document.querySelectorAll('.js-volunteer-list-sm-search-modal__list__close-btn');
	spVolunteerListSearchInputCloseBtn.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
			closeSearchInputModal();
		});
	});


	// 検索の項目クリックでモーダルを開く
	let spVolunteerListSearchOpenBtn = document.querySelectorAll('.js-2column__aside__search-sm__form__item');
	spVolunteerListSearchOpenBtn.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
			let searchTitle = this.getAttribute('data-search-title');
			let openModal = document.querySelector('.js-volunteer-list-sm-search-modal');
			let openInput = document.querySelector('.js-volunteer-list-sm__search-modal__content[data-search-title="' + searchTitle + '"]')
			let ElmnBody = document.querySelector('body');

			openModal.setAttribute('data-is-open', 'true');
			openInput.setAttribute('data-is-open', 'true');
			ElmnBody.classList.add('c-body-modal-widow-fixed');
		});
	});

	let spVolunteerListSearchInput = document.querySelectorAll('.js-volunteer-list-sm-search-modal__content__list__item');
	spVolunteerListSearchInput.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
			let inputValueName = this.getAttribute('data-value-name');
			let inputValue = this.getAttribute('data-value');
			let searchTitle = this.parentNode.parentNode.parentNode.getAttribute('data-search-title');
			let spSearchElmn = document.querySelector('.js-2column__aside__search-sm__form__item[data-search-title="' + searchTitle + '"]');

			let spSearchNameElm = spSearchElmn.querySelector('.js-2column__aside__search-sm__form__item__name');
			let inputElmn = spSearchElmn.querySelector('input[name="' + searchTitle + '"]');

			inputElmn.value = inputValue;
			spSearchNameElm.innerHTML = inputValueName;
			closeSearchInputModal();

		});
	});

	// 初期設定
	let spVolunteerListSearchModalContent = document.querySelectorAll('.js-volunteer-list-sm__search-modal__content');
	spVolunteerListSearchModalContent.forEach(function (elmn, index) {
		elmn.setAttribute('data-is-open', 'false');
	});

	let spVolunteerListSearchModal = document.querySelector('.js-volunteer-list-sm-search-modal');
	if (!!spVolunteerListSearchModal) {
		spVolunteerListSearchModal.setAttribute('data-is-open', 'false');
		spVolunteerListSearchModal.removeAttribute('style');
	}
})