window.addEventListener('DOMContentLoaded', function () {
	// adove font
	(function (d) {
		var config = {
				kitId: 'miv2kjj',
				scriptTimeout: 3000,
				async: true
			},
			h = d.documentElement,
			t = setTimeout(function () {
				h.className = h.className.replace(/\bwf-loading\b/g, "") + " wf-inactive";
			}, config.scriptTimeout),
			tk = d.createElement("script"),
			f = false,
			s = d.getElementsByTagName("script")[0],
			a;
		h.className += " wf-loading";
		tk.src = 'https://use.typekit.net/' + config.kitId + '.js';
		tk.async = true;
		tk.onload = tk.onreadystatechange = function () {
			a = this.readyState;
			if (f || a && a != "complete" && a != "loaded") return;
			f = true;
			clearTimeout(t);
			try {
				Typekit.load(config)
			} catch (e) {}
		};
		s.parentNode.insertBefore(tk, s)
	})(document);

	// ページトップにスクロール
	function scrollToPageTop() {
		let current_positon = document.querySelector('html').scrollTop;

		if (current_positon !== 0) {
			let inner = document.querySelector('html');

			easyScroll({
				'scrollableDomEle': inner,
				'direction': 'top',
				'duration': 1000,
				'easingPreset': 'easeOutQuart',
				'scrollAmount': current_positon
			});

		}
	}


	let toPageTopBtn = document.querySelector('.js-to-page-top');
	if (!!toPageTopBtn) {
		toPageTopBtn.addEventListener('click', scrollToPageTop);
	}

	//SPトグルメニュー
	let toggleNavBtn = document.querySelector('.js-nav__list__btn__hamburger');
	if (!!toggleNavBtn) {
		toggleNavBtn.addEventListener('click', function () {
			let isOpen = this.getAttribute('data-is-open');
			let toggleNav = document.querySelector('.js-toggle-nav');

			if (isOpen == 'false') {
				this.setAttribute('data-is-open', 'true');
				toggleNav.setAttribute('data-is-open', 'true');
			} else {
				this.setAttribute('data-is-open', 'false');
				toggleNav.setAttribute('data-is-open', 'false');
			}
		});
	}

	let fileField = document.querySelectorAll('.l-form__register__item__input__file');
	if(!!fileField){
		fileField.forEach((field)=>{
			let filename =  field.querySelector('.l-label-filename');
			let inputFileArea = field.querySelector('#' + filename.htmlFor);

			inputFileArea.addEventListener('change', function () {
				let file = this.files[0];
				if (file != null) {
					filename.innerHTML = file.name;
					filename.hidden = false;
				}
			});
		})
	}
})