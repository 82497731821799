window.addEventListener('DOMContentLoaded', function () {
    $('#account_organization_no_hp_url').change(function () {
        if(jQuery(this).prop('checked')){
            $('#account_organization_hp_url')
                .prop('disabled', true)
                .removeAttr('required')
                .val("");
        }else{
            $('#account_organization_hp_url')
                .prop('disabled', false)
                .attr('required', true);
        }
    })

    $('#organization_no_hp_url').change(function () {
        if(jQuery(this).prop('checked')){
            $('#organization_hp_url')
                .prop('disabled', true)
                .removeAttr('required')
                .val("");
        }else{
            $('#organization_hp_url')
                .prop('disabled', false)
                .attr('required', true);
        }
    })
})

// 画面ロード時
$(window).on('load', function() {
    if($('#account_organization_no_hp_url').prop('checked')){
        $('#account_organization_hp_url')
            .prop('disabled', true)
            .removeAttr('required')
            .val("");
    }else{
        $('#account_organization_hp_url')
            .prop('disabled', false)
            .attr('required', true);
    }
});

$(window).on('load', function() {
    if($('#organization_no_hp_url').prop('checked')){
        $('#organization_hp_url')
            .prop('disabled', true)
            .removeAttr('required')
            .val("");
    }else{
        $('#organization_hp_url')
            .prop('disabled', false)
            .attr('required', true);
    }
});
