// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import './application/common'
import './application/flash'
import './application/organizer'
import './application/organizer_detail'
import './application/organizer_mypage_applicant'
import './application/organizer_mypage_profile'
import './application/organizer_mypage_volunteer_list'
import './application/top'
import './application/volunteer_detail'
import './application/volunteer_list'

Rails.start()
ActiveStorage.start()
