window.addEventListener('DOMContentLoaded', function () {
	//post menu
	function closePostMenuNav() {
		let openPostMenuBtn = document.querySelector('.js-post__content__menu-btn[data-is-open="true"]');
		console.log(openPostMenuBtn);
		if (!openPostMenuBtn) {
			return;
		}
		openPostMenuBtn.setAttribute('data-is-open', 'false');
		let ElmnBody = document.querySelector('body');
		ElmnBody.removeEventListener('click', closePostMenuNav);
	}

	let postMenuBtn = document.querySelectorAll('.js-post__content__menu-btn');
	postMenuBtn.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
			let isOpen = this.getAttribute('data-is-open');
			if (isOpen == 'false') {
				closePostMenuNav();
				this.setAttribute('data-is-open', 'true');
				let ElmnBody = document.querySelector('body');
				ElmnBody.addEventListener('click', closePostMenuNav);
			} else {
				this.setAttribute('data-is-open', 'false');
			}
		});
	});

	let postMenuNav = document.querySelectorAll('.js-post__content__menu-btn__nav');
	postMenuNav.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			event.stopPropagation();
		});
	});


	//modal window
	function closeModalWindow() {
		let ElmnBody = document.querySelector('body');
		ElmnBody.classList.remove('c-body-modal-widow-fixed');

		let modalWindow = document.querySelector('.js-modal-window');
		modalWindow.style.display = 'none';
	}

	let modalWindow = document.querySelector('.js-modal-window');
	if (!!modalWindow) {
		modalWindow.addEventListener('click', function (event) {
			closeModalWindow();
		});
	}

	let modalContent = document.querySelector('.js-modal-window-content');
	if (!!modalContent) {
		modalContent.addEventListener('click', function (event) {
			event.stopPropagation();
		});
	}

	let closeModalBtn = document.querySelectorAll('.js-modal-window-close');
	closeModalBtn.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			closeModalWindow();
		});
	});

	let openModalBtn = document.querySelectorAll('.js-modal-window-open');
	openModalBtn.forEach(function (elmn, index) {
		elmn.addEventListener('click', function (event) {
			let modalWindow = document.querySelector('.js-modal-window');
			modalWindow.style.display = 'flex';

			let ElmnBody = document.querySelector('body');
			ElmnBody.classList.add('c-body-modal-widow-fixed');
		});
	});
})