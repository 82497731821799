window.addEventListener('DOMContentLoaded', function () {
    function closeFlashWindow() {
        let ElmnBody = document.querySelector('body');
        ElmnBody.classList.remove('c-body-flash-widow-fixed');

        let flashWindow = document.querySelector('.js-flash-window');
        flashWindow.style.display = 'none';
    }

    let flashWindow = document.querySelector('.js-flash-window');
    if (!!flashWindow) {
        flashWindow.addEventListener('click', function (event) {
            closeFlashWindow();
        });
    }

    let flashContent = document.querySelector('.js-flash-window-content');
    if (!!flashContent) {
        flashContent.addEventListener('click', function (event) {
            event.stopPropagation();
        });
    }

    let closeFlashBtn = document.querySelectorAll('.js-flash-window-close');
    closeFlashBtn.forEach(function (elmn, index) {
        elmn.addEventListener('click', function (event) {
            closeFlashWindow();
        });
    });

    let openFlashBtn = document.querySelectorAll('.js-flash-window-open');
    openFlashBtn.forEach(function (elmn, index) {
        elmn.addEventListener('click', function (event) {
            let flashWindow = document.querySelector('.js-flash-window');
            flashWindow.style.display = 'flex';

            let ElmnBody = document.querySelector('body');
            ElmnBody.classList.add('c-body-flash-widow-fixed');
        });
    });
})